<template>
    <!-- 售后 -->
    <div class="afterSales">
        <div class="search">
            <div class="search-l">
                <div class="l-item" style="width: 40%;">
                    <div class="item-label">日期:</div>
                    <div class="item-input" style="width: 80%;">
                        <el-date-picker v-model="dateVal" type="datetimerange" range-separator="至" start-placeholder="开始日期"
                            end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
                            :default-time="['00:00:00', '23:59:59']" style="width: 100%;">
                        </el-date-picker>
                    </div>
                </div>
                <div class="l-item">
                    <div class="item-label">原订单号:</div>
                    <div class="item-input">
                        <el-input v-model="searchForm.ordernumber" placeholder="请输入原订单号" />
                    </div>
                </div>
                <div class="l-item">
                    <div class="item-label">商品名称:</div>
                    <div class="item-input">
                        <el-input v-model="searchForm.goods_name" placeholder="请输入商品名称" />
                    </div>
                </div>
                <div class="l-item">
                    <div class="item-label">处理方式:</div>
                    <div class="item-input">
                        <el-select v-model="searchForm.status" placeholder="请选择处理方式" style="width: 100%;">
                            <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="l-item">
                    <el-button type="warning"
                        @click="page = 1, loading = true, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">搜索</el-button>
                    <el-button
                        @click="loading = true, page = 1, pageNum = 10, searchForm = {}, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">重置</el-button>
                </div>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
            </div>
        </div>
        <div class="table">
            <div class="table-operation">
                <el-button type="success" @click="handleExport">导出</el-button>
                <el-button type="info" icon="el-icon-refresh-left" @click="loading = true, getList()"></el-button>
            </div>
            <Table :data="tableData" :total="total" v-loading="loading"
                @size-change="pageNum = $event, loading = true, getList()"
                @current-change="page = $event, loading = true, getList()">
                <el-table-column label="订单号" align="center" width="150">
                    <template slot-scope="scope">
                        <p>{{ scope.row.order_master.ordernumber }}</p>
                    </template>
                </el-table-column>
                <el-table-column label="购买人/电话" align="center" width="150">
                    <template slot-scope="scope">
                        <p>姓名：{{ scope.row.order_master.contacts }}</p>
                        <p>电话：{{ scope.row.order_master.phone }}</p>
                    </template>
                </el-table-column>
                <el-table-column label="商品名称" align="center" width="150">
                    <template slot-scope="scope">
                        <span>{{ scope.row.order_goods.goods_name }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="商品图" align="center">
                    <template slot-scope="scope">
                        <el-image style="width: 50px; height: 50px" :src="scope.row.order_goods.goods_pic"
                            :preview-src-list="[scope.row.order_goods.goods_pic]">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column label="下单数量" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.order_goods.count }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="下单金额" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.order_goods.amount }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="下单时间" align="center" width="150">
                    <template slot-scope="scope">
                        <span>{{ scope.row.order_master.created_at }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="配送日期" prop="arrived_date" align="center" width="150"></el-table-column>
                <el-table-column label="状态" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 0">待处理</span>
                        <span v-if="scope.row.status == 1">已补发</span>
                        <span v-if="scope.row.status == 2">已退款</span>
                        <span v-if="scope.row.status == 8">其他</span>
                        <span v-if="scope.row.status == 9">已驳回</span>
                    </template>
                </el-table-column>
                <el-table-column label="申请原因" align="center" width="150">
                    <template slot-scope="scope">
                        <span>{{ scope.row.reason }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="申请图片" align="center">
                    <template slot-scope="scope">
                        <el-image style="width: 50px; height: 50px" :src="scope.row.imgs[0]"
                            :preview-src-list="scope.row.imgs">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column label="备注" prop="remark" align="center"></el-table-column>
                <el-table-column label="退款途径" prop="remark" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.refund_type == 0">-</span>
                        <span v-if="scope.row.refund_type == 1">原路退回</span>
                        <span v-if="scope.row.refund_type == 2">线下处理</span>
                    </template>
                </el-table-column>
                <el-table-column label="已退款金额" prop="total" align="center" width="100"></el-table-column>
                <el-table-column label="申请时间" prop="created_at" align="center" width="150"></el-table-column>
                <el-table-column label="处理时间" prop="did_at" align="center" width="150"></el-table-column>
                <el-table-column label="操作" align="center" width="160" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="warning" size="mini" v-if="scope.row.status == 0"
                            @click="form.id = scope.row.id, goodsItem = scope.row, dialogVisible = true">处理</el-button>
                    </template>
                </el-table-column>
            </Table>
        </div>
        <!-- 处理 -->
        <el-dialog title="处理" :visible.sync="dialogVisible" :close-on-click-modal="false" v-el-drag-dialog width="30%">
            <el-form ref="form" :model="form" label-width="100px" style="padding-right: 30px;">
                <el-form-item label="商品名称：" style="margin-bottom: 2px;">
                    <span>{{ goodsItem.order_goods.goods_name }}</span>
                </el-form-item>
                <el-form-item label="下单数量：" style="margin-bottom: 2px;">
                    <span>{{ goodsItem.order_goods.count }}</span>
                </el-form-item>
                <el-form-item label="下单金额：" style="margin-bottom: 2px;">
                    <span>{{ goodsItem.order_goods.amount }}</span>
                </el-form-item>
                <el-form-item label="处理方式：">
                    <el-select v-model="form.status" placeholder="请选择处理方式" style="width: 100%;">
                        <el-option v-for="item in methodList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="金额：" v-if="form.status == 2">
                    <el-input v-model="form.total" placeholder="请输入退款金额"></el-input>
                </el-form-item>
                <el-form-item label="退款途径：" v-if="form.status == 2">
                    <el-select v-model="form.refund_type" placeholder="请选择退款途径" style="width: 100%;">
                        <el-option v-for="item in refundTypeList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注：">
                    <el-input v-model="form.remark" placeholder="请输入备注"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="warning" @click="confirmFn">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
    
<script>
export default {
    data() {
        return {
            dateVal: [],
            searchForm: {
                begin: '',
                end: '',
            },
            statusList: [
                {
                    id: '',
                    name: '全部'
                },
                {
                    id: 0,
                    name: '待处理'
                },
                {
                    id: 1,
                    name: '已补发'
                },
                {
                    id: 2,
                    name: '已退款'
                },
                {
                    id: 8,
                    name: '其他'
                },
                {
                    id: 9,
                    name: '已驳回'
                },
            ],
            tableData: [],
            loading: true,
            page: 1,
            pageNum: 10,
            total: 0,
            // 弹窗
            dialogVisible: false,
            methodList: [
                {
                    id: 1,
                    name: '补发'
                },
                {
                    id: 2,
                    name: '退款'
                },
                {
                    id: 8,
                    name: '其他'
                },
                {
                    id: 9,
                    name: '驳回'
                },
            ],
            refundTypeList: [
                {
                    id: 1,
                    name: '原路退回'
                },
                {
                    id: 2,
                    name: '线下处理'
                },
            ],
            form: {},
            goodsItem: {
                order_goods: {}
            },
        }
    },
    mounted() {
        // 时间
        this.dateVal = [this.getCurrentDate() + ' ' + '00:00:00', this.getCurrentDate() + ' ' + '23:59:59']
        this.searchForm.begin = this.dateVal[0]
        this.searchForm.end = this.dateVal[1]
        // 列表
        this.getList()
    },
    methods: {
        // 获取当前年月日
        getCurrentDate() {
            const today = new Date();
            let year = today.getFullYear();
            let month = (today.getMonth() + 1).toString().padStart(2, '0');
            let day = today.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        getList() {
            this.$http.post(`/erp/v1/order/service_lst`, {
                page: this.page,
                pageNum: this.pageNum,
                ...this.searchForm
            }).then((res) => {
                const { code, data, msg } = res.data
                this.loading = false
                if (code == 200) {
                    this.tableData = data.rows
                    this.tableData.forEach(item => {
                        item.imgs = item.imgs.split(',')
                    });
                    this.total = data.total
                } else {
                    this.$message.error(msg);
                }
            });
        },
        // 导出
        handleExport() {
            this.$http.post(`/erp/v1/order/service_lst`, {
                page: this.page,
                pageNum: this.pageNum,
                ...this.searchForm,
                export: 1
            }).then((res) => {
                const {
                    code,
                    data,
                    msg
                } = res.data
                this.loading = false
                if (code == 200) {
                    window.open(data.url, '_self')
                } else {
                    this.$message.error(msg);
                }
            });
        },
        // 确定
        confirmFn() {
            if (!this.form.status) {
                this.$message.error('请选择处理方式');
                return
            }
            if (this.form.status == 2) {
                if (!this.form.total) {
                    this.$message.error('请输入退款金额');
                    return
                }
                if (!this.form.refund_type) {
                    this.$message.error('请选择退款途径');
                    return
                }
            } else {
                this.form.total = ''
                this.form.refund_type = ''
            }
            this.$http.post(`/erp/v1/order/service_do`, this.form).then((res) => {
                const { code, msg } = res.data
                if (code == 200) {
                    this.$message.success(msg);
                    this.dialogVisible = false
                    this.getList()
                    this.form = {}
                } else {
                    this.$message.error(msg);
                }
            });
        },
        // 删除
        removeFn(item) {
            this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(`/erp/v1/goods/delete`, {
                    goods_ids: item.id.toString()
                }).then((res) => {
                    const { code, msg } = res.data
                    if (code == 200) {
                        this.$message.success(msg);
                        this.getList()
                    } else {
                        this.$message.error(msg);
                    }
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        handleAvatarSuccess(res, file) {
            this.form.goods_pic = res.data.path
            this.$forceUpdate();
        }
    },
}
</script>